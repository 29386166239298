import React, { useState, useEffect, useRef, PropsWithChildren } from "react";
import { Hub } from "aws-amplify/utils";
import { getCurrentUser, fetchAuthSession } from "aws-amplify/auth";
import { mutate } from "swr";

import { enableRum, disableRum, recordError } from "../aws/rum";

interface Session {
  userId?: string | null;
  supplierLocationIds: number[];
  groups: string[];
  isLoggedIn: boolean;
  loading: boolean;
}
const SessionContext = React.createContext<{
  hasGroup: (group: string) => boolean;
  userId?: string | null;
  isLoggedIn: boolean;
  supplierLocationIds: number[];
  showWalkthrough: boolean;
  setShowWalkthrough: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  hasGroup: () => false,
  userId: null,
  isLoggedIn: false,
  supplierLocationIds: [],
  showWalkthrough: false,
  setShowWalkthrough: () => null
});

const SessionContextProvider = ({ children }: PropsWithChildren) => {
  const [session, setSession] = useState<Session>({
    userId: null,
    supplierLocationIds: [],
    groups: [],
    isLoggedIn: false,
    loading: true
  });
  const [showWalkthrough, setShowWalkthrough] = useState(false);
  const hubListenerCancel = useRef<() => void>();

  useEffect(() => {
    hubListenerCancel.current = Hub.listen("auth", ({ payload }) => {
      if (payload.event === "signedIn") {
        return updateAuthState(true);
      }

      if (payload.event === "signedOut") {
        return updateAuthState(false);
      }
    });

    setInitialAuthState();

    return () => {
      hubListenerCancel.current ? hubListenerCancel.current() : null;
    };
  }, []);

  const setInitialAuthState = async () => {
    try {
      await getCurrentUser();
      updateAuthState(true);
    } catch (ex) {
      updateAuthState(false);
    }

    setSession(prev => ({ ...prev, loading: false }));
  };

  const updateAuthState = (authenticated: boolean) => {
    setSession(prev => ({ ...prev, isLoggedIn: authenticated }));
    if (authenticated) {
      extractSessionData();
      enableRum();
    } else {
      clearCache();
      clearSessionData();
      disableRum();
    }
  };

  const extractSessionData = async () => {
    try {
      const session = await fetchAuthSession();
      if (!session.tokens?.idToken) {
        throw new Error("Missing idToken");
      }

      const {
        "cognito:groups": tokenGroups,
        "custom:supplier_location_id": tokenSupplierLocationIds,
        sub
      } = session.tokens.idToken.payload;

      setSession(prev => ({
        ...prev,
        userId: sub,
        supplierLocationIds: (tokenSupplierLocationIds as string).split(",").map(Number),
        groups: (tokenGroups as string[]) ?? []
      }));
    } catch (ex) {
      recordError(ex);
    }
  };

  const clearSessionData = () => {
    setSession(prev => ({ ...prev, userId: null, groups: [], supplierLocationIds: [] }));
  };

  const clearCache = () => {
    mutate(() => true, undefined, { revalidate: false });
  };

  const hasGroup = (group: string) => {
    return session.groups?.includes(group);
  };

  return (
    <SessionContext.Provider
      value={{
        isLoggedIn: session.isLoggedIn,
        hasGroup,
        userId: session.userId,
        supplierLocationIds: session.supplierLocationIds,
        showWalkthrough,
        setShowWalkthrough
      }}>
      {!session.loading ? children : null}
    </SessionContext.Provider>
  );
};

const SessionConsumer = SessionContext.Consumer;

export { SessionContextProvider, SessionConsumer };

export default SessionContext;
