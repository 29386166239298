import { fetchAuthSession } from "aws-amplify/auth";
import { recordError } from "../aws/rum";

const setAuthorisedHeader = async () => {
  try {
    const session = await fetchAuthSession();

    return {
      Authorization: `Bearer ${session.tokens.idToken}`
    };
  } catch (ex) {
    recordError(ex);
    return {
      Authorization: 'Bearer ""'
    };
  }
};

export default setAuthorisedHeader;
