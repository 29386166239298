import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, Image, Tab } from "@bookingcom/bui-react";
import { SignOutIcon, HelpIcon } from "@bookingcom/bui-assets-react/streamline";
import { signOut } from "aws-amplify/auth";

import HeaderV2 from "../components/HeaderV2";
import Walkthrough from "../components/Walkthrough/Walkthrough";
import SessionContext from "../context/SessionContext";
import { PATHS, NAVBAR_LINKS, ZENDESK_LINK, USER_GROUPS, FEATURE_TOGGLE } from "../consts";
import classNames from "../utils/classNames";
import translate from "../utils/translate";
import useFeatureToggle from "../hooks/useFeatureToggle";

const NO_HEADER_PATHS = [PATHS.LOGIN, PATHS.RESET_PASSWORD, PATHS.FIRST_TIME_LOGIN, PATHS.ERROR];

const BACKGROUND_PATHS = [PATHS.LOGIN, PATHS.RESET_PASSWORD, PATHS.FIRST_TIME_LOGIN];

const NO_WRAPPER_PATHS = [PATHS.LANDING_PAGE, PATHS.LEADS_FORM_PAGE, PATHS.LANDING_PAGE_THANK_YOU];

const shouldShowHeader = pathname => NO_HEADER_PATHS.includes(pathname);
const shouldShowBackground = pathname => BACKGROUND_PATHS.includes(pathname);

const INTL_LOGOUT = {
  id: "button.logout",
  defaultMessage: "Logout"
};

const INTL_HELP = {
  id: "navbar.help",
  defaultMessage: "Help"
};
export const Shell = props => {
  const location = useLocation();
  if (NO_WRAPPER_PATHS.includes(location.pathname)) {
    return props.children;
  } else {
    return <ShellActual {...props}></ShellActual>;
  }
};

const ShellActual = ({ children, intl }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { hasGroup, showWalkthrough, setShowWalkthrough } = useContext(SessionContext);
  const [activeTabId, setActiveTabId] = useState("bookings");

  const inBetaProgramGroup = hasGroup(USER_GROUPS.BETA_PROGRAM);

  const geniusEnabled = useFeatureToggle(FEATURE_TOGGLE.GENIUS);

  const filteredNavItems = NAVBAR_LINKS.filter(({ id }) => {
    if ((id === "campaigns" || id === "locations") && !hasGroup(USER_GROUPS.BETA_PROGRAM)) {
      return false;
    }

    return true;
  });

  const geniusFilteredNavItems = geniusEnabled
    ? filteredNavItems
    : filteredNavItems.filter(({ id }) => id !== "genius");

  const navbarItems = geniusFilteredNavItems.map(item => ({
    ...item,
    text: translate(intl, item.formattedMessage),
    linkAttributes: { "data-testid": item.testId }
  }));

  useEffect(() => {
    navbarItems.some(({ path, id }) => {
      const currentPath = `/${pathname.split("/")[1]}`;

      if (path === currentPath) {
        setActiveTabId(id);
      }

      return false;
    });
  }, [pathname, inBetaProgramGroup, navbarItems]);

  const onLogout = () => {
    signOut();
  };

  const onTabChange = tabId => {
    const tab = navbarItems.find(({ id }) => id === tabId);
    navigate(tab.href);
  };

  const logoutText = translate(intl, INTL_LOGOUT);
  const helpText = translate(intl, INTL_HELP);

  const renderHeader = () => (
    <header className="--background-brand-primary">
      <HeaderV2
        logo={
          <Image
            alt="Booking logo"
            asset={{ setName: "images-brand", assetName: "BookingComLogoDarkBackgrounds" }}
            height="20px"
          />
        }
        logoLink="/"
        title="Taxi Partner Portal">
        <Button
          text={helpText}
          icon={<HelpIcon />}
          variant="light"
          className="--button-padding-medium-mobile --button-white"
          onClick={() => window.open(ZENDESK_LINK, "_blank")}
          attributes={{ "data-testid": "header-help-button" }}
        />
        <Button
          text={logoutText}
          icon={<SignOutIcon />}
          variant="light"
          className="--button-padding-medium-mobile --button-white"
          onClick={onLogout}
          attributes={{ "data-testid": "header-logout-button" }}
        />
      </HeaderV2>

      <div className="--container-width-centered">
        <Tab
          onTabChange={onTabChange}
          activeTabId={activeTabId}
          attributes={{ "data-testid": "navbar" }}
          color="inherit"
          borderless
          className="shell__tabs --color-white">
          <Tab.TriggerList>
            {navbarItems.map(item => (
              <Tab.Trigger key={item.id} {...item} />
            ))}
          </Tab.TriggerList>
        </Tab>
      </div>
    </header>
  );

  return (
    <div
      id="main"
      className={classNames(["main", { "main--with-background": shouldShowBackground(pathname) }])}
      data-testid="shell">
      {!shouldShowHeader(pathname) && renderHeader()}
      {children}
      {showWalkthrough && <Walkthrough onComplete={() => setShowWalkthrough(false)} />}
    </div>
  );
};

Shell.propTypes = {
  children: PropTypes.any.isRequired,
  intl: PropTypes.object.isRequired
};

export default injectIntl(Shell);
