import { Amplify } from "aws-amplify";

export default Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: window.env.COGNITO.userPoolId,
      identityPoolId: window.env.COGNITO.identityPoolId,
      userPoolClientId: window.env.COGNITO.clientId
    }
  }
});
